<template>
  <div>
    <!-- 查看链接 -->
    <el-dialog title="查看链接" :append-to-body="true" :visible="linkShow" :show-close="false" @close="close">
      <table class="table2">
        <thead>
        <tr>
          <td>标题</td>
          <td>广告类型</td>
          <td>链接</td>
          <td>图片截图</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{ linkList.title }}</td>
          <td>{{ linkList.category_title }}</td>
          <td>
            <a class="_a" :href="item" target="_blank" v-for="item in linkList.order_urls"
               :key="item.id">{{ item }}</a>

          </td>
          <td>
            <div class="bg_img" @click="order_url_image_show" v-if="linkList.order_url_image!='' ">
              <img :src="linkList.order_url_image" alt="">
            </div>
            <p v-else>-</p>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="flex" style="margin-top: 30px; justify-content: flex-end">
        <button class="copy_but" @click="copy">复制链接</button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
    linkList: Object,
    linkShow: Boolean,

  },
  methods: {
    copy() {
      //参数是复制的内容
      //.then()中的函数是复制成功和复制失败的两个回调函数
      let url = '';
      if (this.linkList.order_urls.length == 1) {
        url = this.linkList.order_urls[0]
      } else {
        this.linkList.order_urls.forEach((item, index) => {
          url = url + this.linkList.order_urls[index] + '\n'
        })
      }
      console.log(url);
      this.$copyText(this.linkList.title + '|' + this.linkList.medium_title + '\n' + url).then(
          (e) => {
            return this.$message({
              message: '复制成功',
              type: 'success',
            })
          },
          (err) => {
            return this.$message({
              message: '复制失败',
              type: 'warning',
            })
          }
      )
    },
    close() {
      console.log('close');
      this.$emit('close')
    },
    order_url_image_show() {
      this.$alert(`<img style="width: 390px;" src='${this.linkList.order_url_image}' />`, '', {
        dangerouslyUseHTMLString: true,
        showCancelButton: false,
        showConfirmButton: false,
        showClose: false,
        closeOnClickModal: true,
        customClass: 'order_url_image_box'
      });
    },
  },
}
</script>

<style lang=scss scoped>
@import '@/scss/MediaOrder';
@import '@/scss/order_work';

::v-deep .el-message-box {
  width: 1000px !important;
}
</style>